// Custom css

.info-bottom {
  position: absolute;
  bottom:20px;
  left:0;
  right: 0;
}
.download-button {
  font-size: 30px !important;
}
.c-media.round img {
  width: 350px;
  height: 350px;
  border: 1px solid #e5e6e8;
  border-radius: 100%;
}

.text-athomegroup {
  margin-top: 110px;
}
.text-clients {
  margin-top: 100px;
  border: 1px solid #d8d8d8;
  padding: 10px 20px;
}

.legende {
  display: inline-block;
  margin-top: 20px;
}

c-margin-r-10 {
  margin-right: 10px;
}
c-margin-r-20 {
  margin-right: 20px;
}